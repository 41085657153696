import { Injectable } from '@angular/core';
import { ActionOptions, BuilderApiService, ConfirmationBackendAction } from '@capturum/builders/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { SalesOrderService } from '@features/sales-order/services/sales-order.service';

@Injectable({
  providedIn: 'root',
})
export class ListBackendConfirmationService extends ConfirmationBackendAction {
  constructor(
    protected readonly apiHttp: BuilderApiService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly translateService: TranslateService,
    private readonly store: Store,
    private salesOrderService: SalesOrderService,
  ) {
    super(apiHttp, confirmationService, translateService);
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    if (options.action.key === 'allocate-automatically') {
      this.executeAllocateAutomaticallyPopup(options, item);

      return of(null);
    } else {
      return super.execute(options, item).pipe(
        tap(() => {
          this.dispatchBackendConfirmation();
        }),
      );
    }
  }

  private dispatchBackendConfirmation(): void {
    this.store.dispatch(new FetchActionsExecution(BuilderActionType.backendConfirmation));
  }

  private defaultSubmit(options: ActionOptions, item: any): void {
    this.apiHttp
      .post(options.configurationKey, options.endpoint, Array.isArray(item) ? item : [item])
      .pipe(take(1))
      .subscribe((response) => {
        this.salesOrderService.showConfirmPopup$.next();
        this.dispatchBackendConfirmation();
      });
  }

  private executeAllocateAutomaticallyPopup(options: ActionOptions, item: any): void {
    this.confirmationService.confirm({
      header: options.confirmation_title ?? this.translateService.instant('builders.confirmation.title'),
      message: options.confirmation_text ?? '',
      accept: () => {
        setTimeout(() => {
          this.defaultSubmit(options, item);
        }, 200);
      },
      reject: () => {
        this.dispatchBackendConfirmation();
      },
    });
  }
}
